import React from "react"
const PageStartBanner = ({ tag, title, cta, onClick }) => {
  return (
    <div className="flex min-h-[200px]  w-full flex-col items-center justify-center bg-lightGray">
      <div className=" flex max-w-screen-xl flex-col items-center justify-center gap-4 py-6 px-4">
        <span className="text-center font-mono text-lg font-semibold uppercase">
          {tag}
        </span>
        <h1 className="tracking-tigh py-2 text-center text-5xl font-extrabold text-primary-600 lg:text-6xl">
          {title}
        </h1>
        {onClick && (
          <button
            className="rounded-full bg-primary-600 px-5 py-2.5 text-xl font-semibold tracking-tight text-white shadow transition-all hover:opacity-90  "
            onClick={onClick}
          >
            <span> {cta}</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default PageStartBanner
