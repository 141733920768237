import React from "react"

import { graphql } from "gatsby"
import { renderRule, StructuredText } from "react-datocms"
import {
  isHeading,
  isList,
  isParagraph,
} from "react-datocms/node_modules/datocms-structured-text-utils"
import { Navigator } from "../components/LanguageHelpers/Navigator"
import Layout from "../components/layout"
import PageStartBanner from "../components/PageStartBanner"

const InnovationPage = ({
  pageContext,
  data: {
    datoCmsInnovation: { header, content },
  },
}) => {
  return (
    <Layout pageData={pageContext}>
      <PageStartBanner tag={header[0].tag} title={header[0].title} />

      <div className="mx-auto mt-4 flex max-w-screen-xl flex-col space-y-4 px-4 py-4">
        <StructuredText
          data={content}
          customNodeRules={[
            renderRule(isHeading, ({ node, children, key }) => {
              const Heading = `h${node.level}`
              return (
                <Heading
                  className="py-2 text-4xl font-bold text-primary-600"
                  key={key}
                >
                  {children}
                </Heading>
              )
            }),
            renderRule(isParagraph, ({ node, children, key }) => {
              return (
                <div className="max-w-screen-sm ">
                  <p key={key}>{children}</p>
                </div>
              )
            }),
            renderRule(isList, ({ style, children, key }) => {
              return (
                <ul className="list-outside list-disc space-y-4 pl-4" key={key}>
                  {children.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              )
            }),
          ]}
          renderInlineRecord={({ record }) => {
            return (
              <Navigator
                className="cursor-pointer font-semibold underline transition-all hover:text-primary-600 "
                recordId={record.id}
              >
                {record.title}
              </Navigator>
            )
          }}
        />
      </div>
    </Layout>
  )
}

export default InnovationPage

export const query = graphql`
  query InnovationQuery($locale: String!) {
    datoCmsInnovation(locale: { eq: $locale }) {
      locale
      slug
      title
      header {
        title
        tag
      }
      content {
        value
        links {
          ... on DatoCmsElevator {
            id: originalId
            __typename
            title
            slug
          }

          ... on DatoCmsElectronic {
            id: originalId
            __typename
            title
            slug
          }
        }
      }
      id: originalId
    }
  }
`
